import {OrbitControls} from '@kibou/three-orbitcontrols-ts';

import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

import {
  AxesHelper,
  BoxGeometry,
  Color,
  BufferGeometry,
  GridHelper,
  Group,
  InstancedMesh,
  LineSegments,
  Material,
  PerspectiveCamera,
  Plane,
  PlaneHelper,
  Points,
  Scene,
  Vector3,
  WebGLRenderer,
  WireframeGeometry,
  Float32BufferAttribute,
  PointLight,
} from 'three';
import {Axis, BoundingBox, initialParams} from './Base3DViewport';
import {
  convertMeshToPoints,
  convertPointsToMesh,
  loadInstancedMeshMatrices,
  ExplicitPoint,
  PointCloudViewportParams,
  SimilarityPoint,
} from './pointCloudLoader';
import {PartPointClouds, sphereGeometry, ThreePoints} from './types';
import {debounce} from 'lodash';
import {View3DViewportParams} from './View3DViewport';
import {SOURCE_PART_COLOR, TARGET_PART_COLOR} from './AnalysisTypeHelper';

export const initialiseScene = (
  scene: Scene,
  camera: PerspectiveCamera,
  setControls: (controls: OrbitControls) => void,
  renderer: WebGLRenderer,
  sceneGroup: Group,
  clippingPlane: Plane
) => {
  // Initialize ThreeJS viewport
  scene.background = new Color(initialParams.backgroundColour);

  // Light emitting from the camera in all directions (lights up the users point of view)
  const pointLight = new PointLight(0xffffff, 0.9);
  pointLight.position.set(0, 0, 1);
  camera.add(pointLight);

  scene.add(camera);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.zoomSpeed = 2;
  setControls(controls);

  // Add part models to scene
  scene.add(sceneGroup);

  const buildPlateSize = 250;

  // Initialise helper models and add to scene
  const axesHelper = new AxesHelper(2 * buildPlateSize);
  axesHelper.name = 'axesHelper';
  axesHelper.visible = false;

  const gridHelper = new GridHelper(buildPlateSize, buildPlateSize / 10);
  gridHelper.name = 'gridHelper';
  gridHelper.visible = true;

  const planeHelper = new PlaneHelper(clippingPlane, buildPlateSize);
  planeHelper.name = 'planeHelper';
  planeHelper.visible = false;

  const helpers = new Group();
  helpers.name = 'helpers';
  helpers.add(axesHelper, gridHelper, planeHelper);
  helpers.visible = false; // Hide until first model is selected
  scene.add(helpers);
};

// Some rough working for these calculations are available at
// smb://aastore.local/aastore/02 - Documentation/05 - Development Documentation [internal]/Webapp 3D viewport initial camera position.pdf
// Basically this works out how far the camera has to be along a line angled
// 30 degrees below the horizontal passing through the bounding box's center
// to view the entire bounding box.
export const resetCamera = (
  bounds: BoundingBox,
  camera: PerspectiveCamera,
  controls: OrbitControls | null,
  renderScene: () => void
) => {
  const minBounds = new Vector3(...Object.values(bounds.min));
  const dimensions = new Vector3(...Object.values(bounds.dimensions));
  const center = minBounds.clone().add(dimensions.clone().divideScalar(2));

  // The Z dimension can be negative because of some coordinate system change weirdness.
  const dimensionsAbs = new Vector3(...Object.values(bounds.dimensions).map(Math.abs));

  const fov = camera.fov * (Math.PI / 180) * 0.8; // Reduce FOV to 80% to add a bit of padding
  const cameraPitch = Math.PI / 6; // Angle down from horizontal

  const boundsYZLength = dimensionsAbs.clone().setX(0).length();

  const minCameraDistToFitVertically = (() => {
    const boundsYZAngle = Math.atan2(dimensionsAbs.y, dimensionsAbs.z);

    const angle1 = fov / 2;
    const angle2 = cameraPitch + boundsYZAngle;
    const angle3 = Math.PI - angle1 - angle2;

    return ((boundsYZLength / 2) * Math.sin(angle3)) / Math.sin(angle1);
  })();

  const minCameraDistToFitHorizontally = (() => {
    const fovWidth = fov * camera.aspect;
    const distToBoundsEdge = dimensionsAbs.x / 2 / Math.tan(fovWidth / 2);
    return distToBoundsEdge + boundsYZLength / 2;
  })();

  const cameraDist = Math.max(minCameraDistToFitVertically, minCameraDistToFitHorizontally);

  const cameraOffset = new Vector3(0, cameraDist * Math.sin(cameraPitch), cameraDist * Math.cos(cameraPitch));
  const cameraPos = center.clone().add(cameraOffset);

  camera.position.copy(cameraPos);
  camera.lookAt(center);
  controls?.target.copy(center);

  renderScene();
};

export const onBoundsChange = (
  showBoundingBox: boolean,
  sceneBounds: BoundingBox | null,
  resetCamera: (bounds: BoundingBox) => void,
  scene: Scene
) => {
  if (sceneBounds == null) {
    // No bounds have been supplied yet, nothing to update
    return;
  }

  const boundsOffset = (sceneBounds.min as Vector3)
    .clone()
    .add((sceneBounds.dimensions as Vector3).clone().divideScalar(2));
  const boundsGeometry = new BoxGeometry(sceneBounds.dimensions.x, sceneBounds.dimensions.y, sceneBounds.dimensions.z);
  const boundsWireframe = new WireframeGeometry(boundsGeometry);
  const boundsLines = new LineSegments(boundsWireframe);
  (boundsLines.material as Material).depthTest = false;
  (boundsLines.material as Material).opacity = 0.25;
  (boundsLines.material as Material).transparent = true;
  boundsLines.position.copy(boundsOffset);
  boundsLines.name = 'boundsLines';
  boundsLines.visible = showBoundingBox;

  // Remove previous bounds from scene, if present
  const previousBounds = scene.getObjectByName('boundsLines');
  if (previousBounds) scene.remove(previousBounds);

  scene.add(boundsLines);

  resetCamera(sceneBounds);
};

export const onBackgroundColourChange = (backgroundColour: string, renderScene: () => void, scene: Scene) => {
  if (!scene) return;
  scene.background = new Color(backgroundColour);
  renderScene();
};

export const toggleClippingPlane = (
  clippingPlaneEnabled: boolean,
  renderScene: () => void,
  renderer: WebGLRenderer
) => {
  if (!renderer) return;
  renderer.localClippingEnabled = clippingPlaneEnabled;
  renderScene();
};

export const onClippingPlaneChange = (
  sceneBounds: BoundingBox | null,
  clippingPlane: Plane,
  newDirection: Axis,
  newPosition: number,
  newReverse: boolean,
  renderScene: () => void
) => {
  if (!sceneBounds) return;
  const reverseFactor = newReverse ? -1 : 1;
  clippingPlane.constant =
    (sceneBounds.min[newDirection] + sceneBounds.dimensions[newDirection] * newPosition) * reverseFactor;
  clippingPlane.normal = {
    x: new Vector3(1, 0, 0),
    y: new Vector3(0, 1, 0),
    z: new Vector3(0, 0, 1),
  }[newDirection].multiplyScalar(-reverseFactor);
  renderScene();
};

const toggleObjectVisibility = (helperName: string, visible: boolean, renderScene: () => void, scene: Scene) => {
  if (!scene) return;
  const helper = scene.getObjectByName(helperName);
  if (!helper) return;
  helper.visible = visible;
  renderScene();
};

export const toggleAxesHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('axesHelper', visible, renderScene, scene);

export const toggleBuildPlatformHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('gridHelper', visible, renderScene, scene);

export const toggleClippingPlaneHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('planeHelper', visible, renderScene, scene);

export const toggleBoundsLines = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('boundsLines', visible, renderScene, scene);

export const toggleHelpers = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('helpers', visible, renderScene, scene);

// Existing fullscreen on Firefox causes this function to be called initially with a width
// which is fullscreen and a height which is not. It is then called again correctly, but by
// for some reason firefox doesn't rerender it (even with changes successfully applied).
// Debouncing this function solves that issue.
export const resizeViewport = debounce(
  (
    camera: PerspectiveCamera,
    renderer: WebGLRenderer,
    renderScene: () => void,
    stageWidth: number,
    stageHeight: number
  ) => {
    if (!renderer) return;

    if (camera) {
      camera.aspect = stageWidth / stageHeight;
      if (camera.view) {
        camera.view.width = stageWidth;
        camera.view.height = stageHeight;
      }
      camera.updateProjectionMatrix();
    }

    renderer.setSize(stageWidth, stageHeight);
    renderScene();
  },
  100
);

export const toggleUse3DPoints = (
  params: PointCloudViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void,
  geometery?: BufferGeometry
) => {
  pointClouds.forEachAnalysisType((group, analysisType) => {
    if (group.children.length === 0) return; // Check early because group.add will complain otherwise
    const newObjects = group.children.map((object) => {
      if (object.userData.isSimilarityPartPointCloud) return object;
      if (object.userData.isPartModel) return object;

      if (params.use3DPoints && object.type === 'Points')
        return convertPointsToMesh(object as Points, analysisType, params, geometery || sphereGeometry);
      if (!params.use3DPoints && object.type === 'Mesh')
        return convertMeshToPoints(object as InstancedMesh, analysisType, params);
      // If we get here, then somehow we already have the correct type of object
      return object;
    });
    group.remove.apply(group, group.children);
    group.add(...newObjects);
  });
  renderScene();
};

export const toggleSimilarityTransparency = (
  params: PointCloudViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  pointClouds.forEachPointCloud((partModel) => {
    if (!partModel.userData.isSimilarityPartPointCloud) {
      (partModel as ThreePoints).material.depthWrite = !params.isTransparent;
    }
    (partModel as ThreePoints).material.opacity = params.isTransparent ? 0.5 : 1;
  });

  renderScene();
};

export const toggleSimilarityGeometry = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  const allGeometryEnabled = params.selectedAnalysisTypes[AnalysisType3D.Geometry];
  const someGeometryEnabled = !!allGeometryEnabled || !!params.sourceGeometryEnabled || !!params.targetGeometryEnabled;

  pointClouds.forEachPointCloud(
    (partModel) => {
      if (partModel.userData.similarityGeometryType === 'source') {
        const sourceEnabled = !!params.sourceGeometryEnabled;
        partModel.visible = sourceEnabled || allGeometryEnabled;
      }
      if (partModel.userData.similarityGeometryType === 'target') {
        const targetEnabled = !!params.targetGeometryEnabled;
        partModel.visible = targetEnabled || allGeometryEnabled;
      }
    },
    {analysisTypes: [AnalysisType3D.Geometry]}
  );

  pointClouds.forEachAnalysisType((group) => (group.visible = someGeometryEnabled), {
    analysisTypes: [AnalysisType3D.Geometry],
  });

  renderScene();
};

export const rotatePoints = (points: ThreePoints, newRotation?: number) => {
  const rotation = points.rotation;
  points.rotation.set(rotation.x, (newRotation || 0) * (Math.PI / 180), rotation.z);
};

export const rotatePointCloud = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  if (params.rotation === undefined || Object.keys(params.rotation).length === 0) return;

  pointClouds.forEachPointCloud((partModel) => {
    if (partModel.userData.isPartModel) {
      if (partModel.userData.partModelUuid in params.rotation!) {
        const newRotation = params.rotation![partModel.userData.partModelUuid];
        const initialRotation = partModel.userData.initialRotation || 0;
        const nextRotation = newRotation - initialRotation;

        rotatePoints(partModel as ThreePoints, nextRotation);
      }
    } else {
      if (partModel.userData.partUuid in params.rotation!) {
        rotatePoints(partModel as ThreePoints, params.rotation![partModel.userData.partUuid]);
      }
    }
  });

  renderScene();
};

export const toggleSimilarityRotationColours = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  pointClouds.forEachPointCloud((partModel) => {
    const partUuid = partModel.userData.partUuid;

    if (params.selectedParts.length === 2) {
      if (partUuid === params.selectedParts[0].uuid) {
        (partModel as ThreePoints).material.color = new Color(SOURCE_PART_COLOR);
      } else {
        (partModel as ThreePoints).material.color = new Color(TARGET_PART_COLOR);
      }
    } else {
      (partModel as ThreePoints).material.color = new Color(params.overrideColour || 0xffffff);
    }
  });

  renderScene();
};

export const toggleAnalysisTypeVisibility = (
  params: View3DViewportParams,
  setParams: React.Dispatch<React.SetStateAction<View3DViewportParams>>,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  let minDefectAreaSize: number | undefined = undefined;
  let maxDefectAreaSize: number | undefined = undefined;

  pointClouds.forEachAnalysisType((group, analysisType) => {
    if (analysisType === AnalysisType3D.PartModel) return;

    const visible = !!params.selectedAnalysisTypes[analysisType];
    group.visible = visible;
    if (visible && params.defectAreaFilter) {
      group.children.forEach((partModel) => {
        partModel.userData.pointData.forEach((point: ExplicitPoint) => {
          if (point.defectArea) {
            if (!minDefectAreaSize || point.defectArea < minDefectAreaSize) {
              minDefectAreaSize = point.defectArea!;
            }
            if (!maxDefectAreaSize || point.defectArea > maxDefectAreaSize) {
              maxDefectAreaSize = point.defectArea!;
            }
          }
        });
      });
    }
  });

  if (params.defectAreaFilter && minDefectAreaSize !== undefined && maxDefectAreaSize !== undefined) {
    let newMin = minDefectAreaSize as number;
    let newMax = maxDefectAreaSize as number;
    if (params.defectAreaFilter.min && params.defectAreaFilter.min !== params.defectAreaSizes?.min) {
      newMin = Math.max(params.defectAreaFilter.min, newMin);
    }
    if (params.defectAreaFilter.max && params.defectAreaFilter.max !== params.defectAreaSizes?.max) {
      newMax = Math.min(params.defectAreaFilter.max, newMax);
    }

    setParams({
      ...params,
      defectAreaFilter: {min: newMin, max: newMax},
      defectAreaSizes: {min: minDefectAreaSize, max: maxDefectAreaSize},
    });
  }

  toggleSimilarityGeometry(params, pointClouds, renderScene);
  renderScene();
};

export const updateNumPointsDisplayed = (
  numPoints: number,
  pointClouds: PartPointClouds,
  numPointCloudsLoaded: number,
  renderScene: () => void
) => {
  // This assumes all point clouds have the same number of points, not true but good enough
  const pointsPerCloud = numPoints / numPointCloudsLoaded;

  pointClouds.forEachPointCloud((partModel) => {
    if (partModel.userData.isPartModel) return;

    partModel.type === 'Points'
      ? (partModel as ThreePoints).geometry.setDrawRange(0, pointsPerCloud)
      : ((partModel as InstancedMesh).count = Math.min(pointsPerCloud, partModel.userData.pointData.length));
  });

  renderScene();
};

export const updatePointSize = debounce(
  (
    pointSize: number,
    pointClouds: PartPointClouds,
    renderScene: () => void,
    shouldDisplayFn?: (point: ExplicitPoint) => boolean,
    getBlockColour?: (point: ExplicitPoint & Partial<SimilarityPoint>) => Color
  ) => {
    pointClouds.forEachPointCloud((partModel) => {
      if (partModel.userData.isPartModel) return;

      if (partModel.userData.isSimilarityPartPointCloud) {
        (partModel as ThreePoints).material.size = pointSize / 4;
      } else if (partModel.type === 'Points') {
        (partModel as ThreePoints).material.size = pointSize;

        // Apply defect area filter
        if (shouldDisplayFn && partModel.userData.analysisType !== 'model') {
          // Save out the original positions if we haven't already. These are needed as positioning can be lost.
          if (!partModel.userData.originalPositions) {
            const originalPositions = Array.from((partModel as ThreePoints).geometry.getAttribute('position').array);
            partModel.userData.originalPositions = originalPositions;
          }

          const values: {colors: Array<number>; positions: Array<number>} = {colors: [], positions: []};

          partModel.userData.pointData.forEach((point: ExplicitPoint, index: number) => {
            if (shouldDisplayFn(point)) {
              values.colors.push(...point.colour.toArray());
              values.positions.push(
                partModel.userData.originalPositions[index * 3],
                partModel.userData.originalPositions[index * 3 + 1],
                partModel.userData.originalPositions[index * 3 + 2]
              );
            }
          });

          (partModel as ThreePoints).geometry.setAttribute('color', new Float32BufferAttribute(values.colors, 3));
          (partModel as ThreePoints).geometry.setAttribute('position', new Float32BufferAttribute(values.positions, 3));
        }
      } else {
        loadInstancedMeshMatrices(
          partModel as InstancedMesh,
          partModel.userData.pointData,
          pointSize,
          shouldDisplayFn,
          getBlockColour
        );
      }
    });
    renderScene();
  },
  50
);

export const updateModelOpacity = (modelOpacity: number, pointClouds: PartPointClouds, renderScene: () => void) => {
  pointClouds.forEachPointCloud((partModel) => ((partModel as ThreePoints).material.opacity = modelOpacity), {
    analysisTypes: [AnalysisType3D.Model, AnalysisType3D.PartModel],
  });
  renderScene();
};

export const updatePointColourOverride = (
  doOverride: boolean,
  overrideColour: string,
  pointClouds: PartPointClouds,
  is3dPoints: boolean,
  renderScene: () => void
) => {
  pointClouds.forEachPointCloud(
    (partModel) => {
      if (partModel.userData.isSimilarityPartPointCloud) return;
      if (partModel.userData.isSimilarityPartPointCloud) return;

      if (doOverride) {
        (partModel as ThreePoints).material.vertexColors = false;
        (partModel as ThreePoints).material.color = new Color(overrideColour);
      } else {
        // Vertex colour multiplies the base material colour rather than replacing it.
        // So, we need to set the material colour back to white.
        (partModel as ThreePoints).material.color = new Color(0xffffff);
        (partModel as ThreePoints).material.vertexColors = is3dPoints || partModel.userData.isPartModel ? false : true;
      }
      (partModel as ThreePoints).material.needsUpdate = true;
    },
    {analysisTypes: [AnalysisType3D.Model, AnalysisType3D.PartModel]}
  );
  renderScene();
};

export const updatePointCloudHighlight = (
  highlightedPartUuid: string | undefined,
  overridePointColour: boolean,
  overrideColour: string,
  pointClouds: PartPointClouds,
  is3dPoints: boolean,
  renderScene: () => void
) => {
  // Clear any existing highlights by applying colour override rules
  updatePointColourOverride(
    overridePointColour,
    overrideColour,
    pointClouds,
    is3dPoints,
    () => {} // No need to render yet
  );
  if (highlightedPartUuid) {
    pointClouds.forEachPointCloud((partModel) => {
      if (
        partModel.userData.partUuid === highlightedPartUuid &&
        partModel.userData.analysisType === AnalysisType3D.Model
      ) {
        (partModel as ThreePoints).material.color = new Color(0xffa500); // orange
        (partModel as ThreePoints).material.vertexColors = false;
        (partModel as ThreePoints).material.needsUpdate = true;
      }
    });
  }
  renderScene();
};
