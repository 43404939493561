import {authenticateGET, loginGET, loginPOST, logoutGET, logoutPOST} from '../../api/ajax/auth';
import {AuthActions} from '../model/auth';
import {IUser, Role} from '@common/api/models/users/IUser';
import {AjaxApiResult} from '../../api/ajax/ajaxWrapper';
import envConfig from '../../envConfig';
import {toast} from 'react-toastify';

export function login(email: string, password: string) {
  return async (dispatch: Function): AjaxApiResult<IUser> => {
    const res = await loginPOST(email, password);
    if (res.success) {
      if (res.data.role > Role.NONE) {
        dispatch({type: AuthActions.AUTHENTICATED, payload: res.data});
      }
    }
    return res;
  };
}

export function authenticate() {
  // We arrived on the page with no state, but we have an existing session id cookie.
  return async (dispatch: Function, _getState: Function) => {
    const res = envConfig.isOnPremise ? await loginGET() : await authenticateGET();
    if (res.success) {
      toast.dismiss('networkError');

      if (res.data.role > Role.NONE) {
        dispatch({type: AuthActions.AUTHENTICATED, payload: res.data});
        return;
      } else {
        return await dispatch(logout());
      }
    } else {
      const serverOfflineDev = process.env.NODE_ENV === 'development' && res.status && res.status === 502;
      if (!window.navigator.onLine || serverOfflineDev) {
        toast('Network connection lost. Please check your internet connection and try again.', {
          type: 'info',
          toastId: 'networkError',
          autoClose: false,
        });
      } else {
        dispatch({type: AuthActions.LOGOUT});
        toast.dismiss('networkError');
      }
    }
  };
}

export function logout() {
  return async (dispatch: Function, _getState: Function) => {
    if (envConfig.isOnPremise) {
      const res = await logoutPOST();
      if (res.success || res.status === 401) {
        await dispatch({type: AuthActions.LOGOUT});
        window.location.reload(); // This clears the redux cache along with any lingering state
      }
    } else {
      const res = await logoutGET();
      if (res.success) {
        if (res.data.logoutURL) {
          window.location.assign(res.data.logoutURL);
        } else {
          await dispatch({type: AuthActions.LOGOUT});
          window.location.reload();
        }
      } else if (res.status === 401) {
        await dispatch({type: AuthActions.LOGOUT});
        window.location.reload(); // This clears the redux cache along with any lingering state
      }
    }
  };
}

export function emailConfirmationToastShown() {
  return async (dispatch: Function, _getState: Function) => {
    dispatch({type: AuthActions.EMAIL_CONFIRMATION_TOAST_SHOWN});
  };
}
