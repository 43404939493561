import React, {useCallback, useState} from 'react';
import {
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  Link,
  Tooltip,
  IconButton,
  Button,
  Box,
  makeStyles,
  CircularProgress,
  Radio,
  ThemeProvider,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {AnalysisType3D, getPrettyAnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {View3DViewportParams} from '../View3DViewport';
import {CloudUpload, DeleteForever, Search} from '@material-ui/icons';
import {IPartModelAttachmentGETResponse} from '@common/api/models/attachments/IPartModelAttachment';
import SearchAndSelect from '../../../Selector/SearchAndSelect';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';
import {useMultiPartUpload} from '../../../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../../../utils/contexts/IMultiPartUploadContext';
import {GenericDialog} from '../../../DialogButton';
import {partModelAttachmentByUuidDELETE} from '../../../../../api/ajax/partModelAttachments';
import maTheme from '../../../../../theme';
import {RootState} from '../../../../../store/reducers';

// Define custom styles
const useStyles = makeStyles({
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default function PointCloudPicker(props: ViewportSidebarProps<View3DViewportParams>) {
  const formLabelClasses = useStyles();

  const availableAnalysisTypes = Object.values(AnalysisType3D).filter(
    (type) => props.params.availableAnalysisTypes[type]
  );
  const selectedPartUuids = props.params.selectedParts.map((part) => part.uuid);

  // Sub-sort by uuid to handle conflicting names and preserve correct sorting
  const comparePartNames = (a: IPartGETResponse, b: IPartGETResponse) =>
    `${a.name}-${a.uuid}` > `${b.name}-${b.uuid}` ? 1 : -1;

  return (
    <>
      <Grid item style={{width: 'inherit'}}>
        <Typography>Part selection</Typography>
        <PartListContainer>
          <div style={{paddingBottom: 5}}>
            {props.params.selectedParts.length}/{props.params.availableParts.length} parts selected.{' '}
            <Link href="#" onClick={() => props.setParams({...props.params, selectedParts: []})}>
              Deselect all
            </Link>
          </div>
          {props.params.availableParts.length > 0 ? (
            <PartsFormGroup>
              {props.params.availableParts.sort(comparePartNames).map((part) => (
                <Grid
                  key={`${part.name}-${part.uuid}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    maxWidth: '100%',
                    flexWrap: 'nowrap',
                    borderRadius: 5,
                    backgroundColor: props.params.hoveredPartUuid === part.uuid ? '#222' : undefined,
                  }}
                  onMouseEnter={() => {
                    // Only hover selected parts
                    if (!selectedPartUuids.includes(part.uuid)) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: part.uuid,
                    });
                  }}
                  onMouseLeave={() => {
                    // Make sure we don't remove the hover if another part is already hovered
                    if (props.params.hoveredPartUuid !== part.uuid) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: undefined,
                    });
                  }}
                >
                  <Grid
                    item
                    style={{
                      maxWidth: `calc(100% - ${selectedPartUuids.includes(part.uuid) ? '18px' : '0px'})`,
                      overflow: 'hidden',
                    }}
                  >
                    <Tooltip title={<Typography>{part.name}</Typography>}>
                      <FormControlLabel
                        value={part.uuid}
                        control={
                          <Checkbox
                            checked={selectedPartUuids.includes(part.uuid)}
                            onChange={(_, checked) =>
                              props.setParams({
                                ...props.params,
                                selectedParts: checked
                                  ? [...props.params.selectedParts, part]
                                  : props.params.selectedParts.filter((p) => p.uuid !== part.uuid),
                              })
                            }
                            color="primary"
                            size="small"
                            style={{height: '100%'}}
                          />
                        }
                        classes={{label: formLabelClasses.label}}
                        label={part.name}
                        style={{height: '1.5em', maxWidth: 'calc(100% + 12px)'}}
                        disabled={props.viewportState === 'loading' || props.params.partModelRotationMode}
                      />
                    </Tooltip>
                  </Grid>
                  {props.params.selectedParts.includes(part) && (
                    <Grid item style={{height: '18px', paddingLeft: '5px', marginRight: '-5px'}}>
                      <Tooltip title="Centre camera on this part">
                        <IconButton size="small" onClick={() => props.centreCameraOnPart?.(part.uuid)}>
                          <Search fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ))}
            </PartsFormGroup>
          ) : (
            'No parts available.'
          )}
        </PartListContainer>
      </Grid>

      <Grid item style={{width: 'inherit'}}>
        <Typography>Analysis type selection</Typography>
        <div
          style={{
            maxHeight: 400,
            overflowX: 'scroll',
            padding: 10,
            margin: '10px 0 10px 0',
            border: '2px solid gray',
            whiteSpace: 'nowrap',
          }}
        >
          <FormGroup>
            {!props.params.partModelRotationMode && availableAnalysisTypes.length > 0 ? (
              availableAnalysisTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={props.params.selectedAnalysisTypes[type]}
                      onChange={(_, checked) =>
                        props.setParams({
                          ...props.params,
                          selectedAnalysisTypes: {
                            ...props.params.selectedAnalysisTypes,
                            [type]: checked,
                          },
                          modelOpacity: Object.entries(props.params.selectedAnalysisTypes).filter(
                            ([key, selected]) => key !== 'model' && !!selected
                          )?.length
                            ? props.params.modelOpacity
                            : 0.1,
                        })
                      }
                      color="primary"
                      size="small"
                      style={{height: '100%'}}
                    />
                  }
                  label={getPrettyAnalysisType3D(type)}
                  style={{height: '1.5em'}}
                  disabled={props.viewportState === 'loading'}
                />
              ))
            ) : props.params.selectedParts.length > 0 ? (
              'No analysis types available'
            ) : (
              // Disabled mock checkbox for before user selects part
              <FormControlLabel
                control={<Checkbox checked disabled color="primary" size="small" />}
                label="Model"
                style={{height: '1.5em'}}
              />
            )}
          </FormGroup>
        </div>
      </Grid>
    </>
  );
}

export const PartModelPicker = (props: ViewportSidebarProps<View3DViewportParams>) => {
  const formLabelClasses = useStyles();
  const [deleting, setDeleting] = useState<string | undefined>();
  const [partUploaderOpen, setPartUploaderOpen] = useState(false);
  const theme = useSelector((state: RootState) => state.theme);

  if (!props.params.availablePartModels || !props.params.selectedPartModels) return <></>;

  const selectedPartUuids = props.params.selectedPartModels.map((part) => part.uuid);

  // Sub-sort by uuid to handle conflicting names and preserve correct sorting
  const comparePartNames = (a: IPartModelAttachmentGETResponse, b: IPartModelAttachmentGETResponse) =>
    `${a.partName}-${a.uuid}` > `${b.partName}-${b.uuid}` ? 1 : -1;

  const deleteModel = async (partModelUuid: string) => {
    setDeleting(partModelUuid);
    await partModelAttachmentByUuidDELETE(partModelUuid);
    props.setParams({
      ...props.params,
      availablePartModels: props.params.availablePartModels!.filter((p) => p.uuid !== partModelUuid),
      selectedPartModels: props.params.selectedPartModels!.filter((p) => p.uuid !== partModelUuid),
    });
    setDeleting(undefined);
  };

  return (
    <>
      <Grid item style={{width: 'inherit'}}>
        <Typography>Part Model selection</Typography>
        <PartListContainer>
          <div style={{paddingBottom: 5}}>
            {props.params.selectedPartModels.length}/{props.params.availablePartModels.length} models selected.{' '}
            <Link href="#" onClick={() => props.setParams({...props.params, selectedPartModels: []})}>
              Deselect all
            </Link>
          </div>
          {props.params.availablePartModels.length > 0 ? (
            <PartsFormGroup>
              {props.params.availablePartModels.sort(comparePartNames).map((partModel) => (
                <Grid
                  key={`${partModel.partName}-${partModel.uuid}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    maxWidth: '100%',
                    flexWrap: 'nowrap',
                    borderRadius: 5,
                    backgroundColor: props.params.hoveredPartUuid === partModel.partUuid ? '#222' : undefined,
                  }}
                  onMouseEnter={() => {
                    // Only hover selected parts
                    if (!selectedPartUuids.includes(partModel.uuid)) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: partModel.partUuid,
                    });
                  }}
                  onMouseLeave={() => {
                    // Make sure we don't remove the hover if another part is already hovered
                    if (props.params.hoveredPartUuid !== partModel.partUuid) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: undefined,
                    });
                  }}
                >
                  <Grid
                    item
                    style={{
                      maxWidth: `calc(100% - ${selectedPartUuids.includes(partModel.uuid) ? '36px' : '18px'})`,
                      overflow: 'hidden',
                    }}
                  >
                    <Tooltip title={<Typography>{partModel.partName}</Typography>}>
                      <FormControlLabel
                        value={partModel.uuid}
                        control={
                          props.params.partModelRotationMode ? (
                            <Radio
                              checked={selectedPartUuids.includes(partModel.uuid)}
                              onChange={(_, checked) => {
                                props.setParams({
                                  ...props.params,
                                  selectedPartModels: checked ? [partModel] : [],
                                  selectedParts: checked
                                    ? [props.params.availableParts.find((part) => part.uuid === partModel.partUuid)!]
                                    : [],
                                });
                              }}
                              color="primary"
                              size="small"
                              style={{height: '100%'}}
                            />
                          ) : (
                            <Checkbox
                              checked={selectedPartUuids.includes(partModel.uuid)}
                              onChange={(_, checked) => {
                                props.setParams({
                                  ...props.params,
                                  selectedPartModels: checked
                                    ? [...props.params.selectedPartModels!, partModel]
                                    : props.params.selectedPartModels!.filter((p) => p.uuid !== partModel.uuid),
                                });
                              }}
                              color="primary"
                              size="small"
                              style={{height: '100%'}}
                            />
                          )
                        }
                        classes={{label: formLabelClasses.label}}
                        label={partModel.partName}
                        style={{height: '1.5em', maxWidth: 'calc(100% + 12px)'}}
                        disabled={props.viewportState === 'loading'}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{height: '18px', marginTop: '-2px', paddingLeft: '5px', marginRight: '-5px'}}>
                    <Tooltip title="Delete Model">
                      <IconButton size="small" onClick={() => deleteModel(partModel.uuid)} disabled={!!deleting}>
                        {deleting === partModel.uuid ? (
                          <CircularProgress size="18px" />
                        ) : (
                          <DeleteForever fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {props.params.selectedPartModels!.includes(partModel) && (
                    <Grid item style={{height: '18px', paddingLeft: '5px', marginRight: '-5px'}}>
                      <Tooltip title="Centre camera on this part">
                        <IconButton size="small" onClick={() => props.centreCameraOnPart?.(partModel.uuid)}>
                          <Search fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ))}
            </PartsFormGroup>
          ) : (
            'No parts available.'
          )}
          <div style={{paddingTop: '6px'}}>
            <Button color="primary" variant="contained" fullWidth onClick={() => setPartUploaderOpen(true)}>
              Upload Part Model
            </Button>
          </div>
        </PartListContainer>
        <Grid item>
          <Tooltip
            title={
              <Typography>
                Rotate your part STL models to align with our point cloud. Only one part may be selected at a time when
                in this mode.
              </Typography>
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={props.params.partModelRotationMode}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    props.setParams({
                      ...props.params,
                      selectedPartModels: [],
                      selectedParts: [],
                      partModelRotationMode: checked,
                    });
                  }}
                  name="enablePartModelRotation"
                />
              }
              label="Model Rotation Mode"
            />
          </Tooltip>
        </Grid>
        {/* View3DViewportSidebar sets it's own "Dark" theme. We're reverting it back to the main app theme here. */}
        <ThemeProvider theme={maTheme[theme.currentTheme]}>
          <GenericDialog
            title="Upload Part Model"
            isOpen={partUploaderOpen}
            closeDialog={() => setPartUploaderOpen(false)}
            closeText="Close"
            hideCloseTextButton
            content={
              <Box width="100%" style={{overflowY: 'auto'}}>
                <PartStlUploader
                  parts={props.params.availableParts}
                  alreadyUploadedPartUuids={props.params.availablePartModels.map((part) => part.partUuid)}
                  closeModal={() => setPartUploaderOpen(false)}
                  postUploadCallback={(partUuid) => {
                    props.setParams({
                      ...props.params,
                      partModelRotationMode: true,
                      selectedParts: [props.params.availableParts.find((part) => part.uuid === partUuid)!],
                      selectedPartModels: [],
                    });
                  }}
                />
              </Box>
            }
            maxWidth="lg"
          />
        </ThemeProvider>
      </Grid>
    </>
  );
};

const PartStlUploader = ({
  parts,
  alreadyUploadedPartUuids,
  closeModal,
  postUploadCallback,
}: {
  parts: IPartGETResponse[];
  alreadyUploadedPartUuids: string[];
  closeModal: () => void;
  postUploadCallback: (partUuid: string) => void;
}) => {
  const [part, setPart] = useState<IPartGETResponse>();
  const {onDrop} = useMultiPartUpload();

  const searchParts = useCallback(
    async (search: string): Promise<IPartGETResponse[]> => {
      const excludeAlreadyUploadedParts = parts.filter((part) => !alreadyUploadedPartUuids.includes(part.uuid));
      if (!search) return excludeAlreadyUploadedParts;

      return excludeAlreadyUploadedParts.filter((part) => part.name.toLowerCase().includes(search.toLowerCase()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alreadyUploadedPartUuids.length, parts.length]
  );

  function dropZoneOnDrop(droppedFiles: File[]) {
    onDrop(droppedFiles, MultiPartUploadResourceType.partModelAttachment, {
      resourceUuid: part!.uuid,
      orgUuid: part!.organizationUuid,
      setNumFilesUploading: () => {},
      postUploadCallback: () => postUploadCallback(part!.uuid),
    });
    closeModal();
  }

  return (
    <Box padding="12px" style={{maxWidth: '332px', width: '332px'}}>
      <SearchAndSelect
        selected={part}
        setSelected={setPart}
        getSuggestionValue={(resource) => (resource as any).name}
        isSelected={(resource) => (part ? part.uuid === (resource as any).uuid : false)}
        fetchFunction={searchParts}
        label="Select Part"
      />
      {!!part && (
        <Dropzone onDrop={dropZoneOnDrop} noClick accept={'.stl'}>
          {({getRootProps, getInputProps, open, isDragActive}) => (
            <CustomDiv isDragActive={isDragActive}>
              <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <Row>
                    <CloudUpload color="primary" style={{fontSize: 32}} />
                  </Row>
                  <Row>
                    <Typography variant="h6">Drag & drop to upload</Typography>
                  </Row>
                  <Row>
                    <Typography variant="subtitle1">or</Typography>
                  </Row>
                  <Row>
                    <Button variant="contained" color="primary" onClick={open} size="medium" fullWidth={false}>
                      Browse files...
                    </Button>
                  </Row>
                  <Row>
                    <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>Only accepts STL files</Typography>
                  </Row>
                </div>
              </section>
            </CustomDiv>
          )}
        </Dropzone>
      )}
    </Box>
  );
};

const CustomDiv = styled.div<{isDragActive: boolean}>`
  border: dashed 2px ${({isDragActive}) => (isDragActive ? grayColor[6] : grayColor[4])};
  ${({isDragActive}) => (isDragActive ? `background-color: ${grayColor[5]};` : '')};
  transition: all 0.3s;
  border-radius: 5px;
  padding: 24px 32px;
  text-align: center;
  margin-top: 16px;
  .dropzone {
    display: grid;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0px;
`;

const PartListContainer = styled.div`
  max-height: 400px;
  overflow-x: scroll;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: 2px solid gray;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
`;

const PartsFormGroup = styled(FormGroup)`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
  padding-right: 4px;
`;
