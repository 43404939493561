import {ScanStrategy} from '@common/api/models/attachments/ISliceAttachment';
import {
  materialAttachmentMultipartUploadAbortPOST,
  materialAttachmentMultipartUploadCompletePOST,
  materialAttachmentMultipartUploadInitiatePOST,
} from '../../api/ajax/materialAttachments';
import {
  sliceAttachmentMultipartUploadAbortPOST,
  sliceAttachmentMultipartUploadCompletePOST,
  sliceAttachmentMultipartUploadInitiatePOST,
} from '../../api/ajax/sliceAttachments';
import {FileStates} from '../FileUploadContext';
import {
  partModelAttachmentMultipartUploadAbortPOST,
  partModelAttachmentMultipartUploadCompletePOST,
  partModelAttachmentMultipartUploadInitiatePOST,
} from '../../api/ajax/partModelAttachments';

export interface OnDropOptions {
  resourceUuid: string;
  orgUuid: string;
  setNumFilesUploading: React.Dispatch<React.SetStateAction<number>>;
  scanStrategy?: ScanStrategy;
  isBuildPhoto?: boolean;
  postUploadCallback?: () => void;
}

export type OnDropFn = (
  droppedFiles: File[],
  resourceType: MultiPartUploadResourceType,
  {resourceUuid, orgUuid, setNumFilesUploading, scanStrategy}: OnDropOptions
) => void;

export type MultiPartUploadStore = {files: FileStates; onDrop: OnDropFn};

export enum MultiPartUploadResourceType {
  material,
  sliceAttachment,
  partModelAttachment,
}

export const MULTI_PART_REQUESTS = {
  [MultiPartUploadResourceType.material]: {
    initiate: materialAttachmentMultipartUploadInitiatePOST,
    complete: materialAttachmentMultipartUploadCompletePOST,
    abort: materialAttachmentMultipartUploadAbortPOST,
  },
  [MultiPartUploadResourceType.sliceAttachment]: {
    initiate: sliceAttachmentMultipartUploadInitiatePOST,
    complete: sliceAttachmentMultipartUploadCompletePOST,
    abort: sliceAttachmentMultipartUploadAbortPOST,
  },
  [MultiPartUploadResourceType.partModelAttachment]: {
    initiate: partModelAttachmentMultipartUploadInitiatePOST,
    complete: partModelAttachmentMultipartUploadCompletePOST,
    abort: partModelAttachmentMultipartUploadAbortPOST,
  },
};
