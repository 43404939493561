import axios from 'axios';
import {clone} from 'lodash';
import pako from 'pako';
import protobuf, {Type} from 'protobufjs';
import semver from 'semver';
import {
  BufferGeometry,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  InstancedMesh,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshPhysicalMaterial,
  Object3D,
  Plane,
  Points,
  PointsMaterial,
  Quaternion,
  Vector3,
} from 'three';

import {AnalysisType3D, comparisonTypeToAnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {BoxGeometry} from 'three';
import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';

import {pointCloudDownloadUrlGET} from '../../../../api/ajax/pointCloud';
import {cmap} from '../../../../utils/colormap';
import {BoundingBox} from './Base3DViewport';
import {Comparison, Point, PointCloud2} from './pointCloudV2';
import {Coord, PointCloud3} from './pointCloudV3';
import {View3DViewportParams} from './View3DViewport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {partModelAttachmentDownloadUrlGET} from '../../../../api/ajax/partModelAttachments';

export interface PointCloudViewportParams {
  pointLimit: number;
  pointSize: number;
  modelOpacity: number;
  overridePointColour: boolean;
  overrideColour: string;

  defectAreaFilter?: {min?: number; max?: number};
  defectAreaSizes?: {min?: number; max?: number};

  use3DPoints: boolean;
  isTransparent?: boolean;

  clippingPlane: Plane;
}

export const initialPointCloudParams: PointCloudViewportParams = {
  pointLimit: 5e5,
  pointSize: 0.2,
  modelOpacity: 0.5,
  overridePointColour: false,
  overrideColour: '#bfb4b4',
  defectAreaFilter: {},
  defectAreaSizes: {},

  use3DPoints: false,

  clippingPlane: new Plane(),
};

export interface PointCloudLoadSuccess {
  success: true;
  object: Object3D;
  bounds: BoundingBox;
}

interface PointCloudLoadFailure {
  success: false;
  error: string;
}

type PointCloudLoadResult = PointCloudLoadSuccess | PointCloudLoadFailure;

export interface ComparisonLoadSuccess {
  success: true;
  comparisonPoints: {[key in AnalysisType3D]?: Object3D};
}

type ComparisonLoadResult = ComparisonLoadSuccess | PointCloudLoadFailure;

interface PointCloudParseSuccess {
  success: true;
  pointCloud: PointCloud2 | PointCloud3;
}

type PointCloudParseResult = PointCloudParseSuccess | PointCloudLoadFailure;

// Unlike the Point interface from the point cloud definition,
// here colour and scale are stored explicitly
export interface ExplicitPoint {
  position: Vector3;
  colour: Color;
  scale: number;
  defectArea?: number;
}

export interface SimilarityPoint extends ExplicitPoint {
  similarityCoefficient: number;
}

export const redBlueDivergentColourMap = cmap({
  colormap: 'RdBu',
  nshades: 200,
}).reverse();

export const turboColourMap = cmap({
  colormap: 'turbo',
  nshades: 100,
});

const loadPointCloudBounds = (pointCloud: PointCloud2 | PointCloud3): BoundingBox => {
  let min = new Vector3(pointCloud.bounds[0], pointCloud.bounds[2], -pointCloud.bounds[1]);
  let dimensions = new Vector3(pointCloud.bounds[3], pointCloud.bounds[5], -pointCloud.bounds[4]);
  if (!!pointCloud.layerBounds) {
    let layerBounds = {
      min: pointCloud.layerBounds[0],
      max: pointCloud.layerBounds[1],
    };
    return {min, dimensions, layerBounds};
  }

  return {min, dimensions};
};

export const pointCloudBoundsFromPart = (part: IPartGETResponse): BoundingBox | null => {
  if (!part || !part.bounds || part.bounds.length !== 6) {
    return null;
  }
  let min = new Vector3(part.bounds[0], part.bounds[2], -part.bounds[1]);
  let dimensions = new Vector3(part.bounds[3], part.bounds[5], -part.bounds[4]);

  if (!!part.layerStart && !!part.layerEnd) {
    let layerBounds = {
      min: part.layerStart!,
      max: part.layerEnd!,
    };
    return {min, dimensions, layerBounds};
  }

  return {min, dimensions};
};

const parsePointCloud = (apiRes: any, fileExtension: string): PointCloudParseResult => {
  if (apiRes.success !== undefined && !apiRes.success) {
    return {success: false, error: apiRes.error};
  }

  switch (fileExtension) {
    case 'aapc':
      return parseProtoPointCloud(apiRes);
    case 'gz':
      return parseGzJsonPointCloud(apiRes);
    default:
      return {success: false, error: 'Unknown point cloud format'};
  }
};

let protobufParser: Type | null = null;
protobuf.load('/files/point_cloud.proto').then((res) => (protobufParser = res.lookupType('PointCloud')));

// Convert the .aapc bytes into a javascript object
const parseProtoPointCloud = (apiRes: any): PointCloudParseResult => {
  // Protobuf parser *should* always be loaded by the time the point cloud is downloaded...
  if (!protobufParser) return {success: false, error: 'Point cloud parser not loaded'};

  // Load point cloud object
  try {
    const pointCloudParsed = protobufParser.decode(new Uint8Array(apiRes)) as any;

    // The proto format stores data with key-value pairs, but the newer json
    // format stores data as tuples. We need to convert the KVPs to tuples.
    const pointCloud: PointCloud2 = {
      ...pointCloudParsed,
      points: pointCloudParsed.points.map((point: any) => ({
        ...point,
        coord: [point.coord.x, point.coord.y, point.coord.z],
      })),
      colours: pointCloudParsed.colours.map((colour: any) => [colour.r, colour.g, colour.b]),
      bounds: [
        pointCloudParsed.bounds.min.x,
        pointCloudParsed.bounds.min.y,
        pointCloudParsed.bounds.min.z,
        pointCloudParsed.bounds.dimensions.x,
        pointCloudParsed.bounds.dimensions.y,
        pointCloudParsed.bounds.dimensions.z,
      ],
    };

    return {success: true, pointCloud};
  } catch (e) {
    return {success: false, error: 'Could not read 3D data'};
  }
};

const parseGzJsonPointCloud = (apiRes: any): PointCloudParseResult => {
  const inflated = pako.inflate(apiRes, {to: 'string'});
  const json = JSON.parse(inflated);
  return {success: true, pointCloud: json};
};

// Convert parsed point cloud into list of points
const loadPoints = (pointCloud: PointCloud2 | PointCloud3): ExplicitPoint[] => {
  const minSize = pointCloud.minSize && pointCloud.minSize > 0 ? pointCloud.minSize : 1;

  if (semver.satisfies(pointCloud.version, '>=3.0.0')) {
    const pointCloud3 = pointCloud as PointCloud3;
    const indexedColours = pointCloud3.colourMap
      ? pointCloud3.colourMap.map((colour) => new Color(...colour.map((value) => value / 255)))
      : [];
    return pointCloud3.points.map((point, idx) => ({
      position: new Vector3(point[0], point[2], -point[1]),
      colour:
        indexedColours.length > 0
          ? indexedColours[pointCloud3.colourIndex ? pointCloud3.colourIndex[idx] : 0]
          : new Color(0xffffff),
      scale: pointCloud3.segments ? minSize * Math.pow(2, pointCloud3.segments[idx].sizeExp) : minSize,
      defectArea: pointCloud3.defectAreas ? pointCloud3.defectAreas[idx] : undefined,
    }));
  } else {
    const pointCloud2 = pointCloud as PointCloud2;
    const indexedColours = pointCloud2.colours
      ? pointCloud2.colours.map((colour) => new Color(...colour.map((value) => value / 255)))
      : [];
    return pointCloud2.points.map((point) => ({
      position: new Vector3(point.coord[0], point.coord[2], -point.coord[1]),
      colour: indexedColours.length > 0 ? indexedColours[point.colourIndex ?? 0] : new Color(0xffffff),
      scale: minSize * Math.pow(2, point.sizeExp ?? 0),
    }));
  }
};

type ComparisonPoints = {[key in AnalysisType3D]?: SimilarityPoint[]};

const loadComparisons = (pointCloud: PointCloud2 | PointCloud3, _isScaled: boolean): ComparisonPoints => {
  const comparisons: ComparisonPoints = {};

  const minSize = pointCloud.minSize && pointCloud.minSize > 0 ? pointCloud.minSize : 1;

  let position: Vector3, scale: number, pointComparisons: Comparison[];

  pointCloud.points.forEach((point: Point | Coord, i: number) => {
    if ((pointCloud as PointCloud3).segments) {
      pointComparisons =
        //@ts-ignore
        pointCloud.segments[i].comparison ||
        //@ts-ignore
        pointCloud.segments[i].comparisons;
    } else {
      pointComparisons =
        //@ts-ignore
        point.comparison || point.comparisons;
    }
  });

  pointCloud.points.forEach((point: Point | Coord, idx: number) => {
    if (semver.satisfies(pointCloud.version, '>=3.0.0')) {
      position = new Vector3((point as Coord)[0], (point as Coord)[2], -(point as Coord)[1]);
      if ((pointCloud as PointCloud3).segments) {
        //@ts-ignore
        scale = minSize * Math.pow(2, pointCloud.segments[idx].sizeExp);

        pointComparisons =
          //@ts-ignore
          pointCloud.segments[idx].comparison ||
          //@ts-ignore
          pointCloud.segments[idx].comparisons;
      }
    } else {
      position = new Vector3((point as Point).coord[0], (point as Point).coord[2], -(point as Point).coord[1]);
      scale = minSize * Math.pow(2, (point as Point).sizeExp ?? 0);
      // For backwards compatibility JSON vs Protobuf (proto may have changed incorrectly in analysis)
      pointComparisons =
        //@ts-ignore
        point.comparison || point.comparisons;
    }
    pointComparisons.forEach((comparison) => {
      const type = comparisonTypeToAnalysisType3D(comparison.type || 0);
      const metric = comparison.comparisonMetric || 0;
      if (!comparisons[type]) comparisons[type] = [];

      comparisons[type]!.push({
        position,
        scale,
        colour: new Color(),
        similarityCoefficient: type === AnalysisType3D.ASIM ? (1 - metric) * 100 : metric * 100,
      });
    });
  });
  return comparisons;
};

const loadPointsObject = (points: ExplicitPoint[], analysisType: AnalysisType3D, params: PointCloudViewportParams) => {
  const positions = points.flatMap((point: ExplicitPoint) => point.position.toArray());
  const colours = points.flatMap((point: ExplicitPoint) => point.colour.toArray());

  const geometry = new BufferGeometry();
  geometry.setDrawRange(0, params.pointLimit);
  geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
  geometry.setAttribute('color', new Float32BufferAttribute(colours, 3));

  const isModel = analysisType === AnalysisType3D.Model;
  const material = new PointsMaterial({
    transparent: isModel,
    clippingPlanes: [params.clippingPlane],
    size: params.pointSize,
    opacity: isModel ? params.modelOpacity : 1,
    ...(isModel && params.overridePointColour
      ? {
          vertexColors: false,
          color: new Color(params.overrideColour),
        }
      : {
          vertexColors: true,
          color: new Color(0xffffff),
        }),
  });

  const object = new Points(geometry, material);

  // We need to render the model after the defects, so that the defects are
  // visible through the model when transparent.
  object.renderOrder = analysisType === AnalysisType3D.Model ? 2 : 1;
  return object;
};

// Parse a downloaded point cloud protobuf and add it to the scene as a cloud of points.
export const loadPointCloudAsPoints = (
  pointCloud: PointCloud2 | PointCloud3,
  analysisType: AnalysisType3D,
  params: View3DViewportParams
): PointCloudLoadResult => {
  const points = loadPoints(pointCloud);

  const object = loadPointsObject(points, analysisType, params);
  object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
  object.userData.pointData = points;

  // Bounds are usually loaded from the part in the DB. We can also load them from the point cloud itself.
  // For parts, the result should be the same.
  // This is needed for similarity point clouds, as they don't store bounds in the DB and they differ from the part bounds.
  const bounds = loadPointCloudBounds(pointCloud);

  return {success: true, object, bounds};
};

export const convertMeshToPoints = (
  mesh: InstancedMesh,
  analysisType: AnalysisType3D,
  params: PointCloudViewportParams
) => {
  const points = mesh.userData.pointData;
  const pointCloud = loadPointsObject(points, analysisType, params);
  pointCloud.visible = mesh.visible;
  pointCloud.name = mesh.name;
  pointCloud.userData = mesh.userData;
  // @ts-ignore
  pointCloud.geometry.setAttribute('color', mesh.instanceColor);
  return pointCloud;
};

export const loadInstancedMeshMatrices = (
  mesh: InstancedMesh,
  points: ExplicitPoint[],
  scale: number,
  shouldDisplayFn?: (point: ExplicitPoint) => boolean,
  getBlockColour?: (point: ExplicitPoint & Partial<SimilarityPoint>) => Color
) => {
  // We don't ever need rotation, fix it to the unit quaternion
  const rotation = new Quaternion();

  const getTranslationMatrix = (point: ExplicitPoint, outputMatrix: Matrix4) => {
    const scaleVector = new Vector3();
    scaleVector.x = scaleVector.y = scaleVector.z = scale * point.scale;
    outputMatrix.compose(point.position, rotation, scaleVector);
  };

  const tempMatrix = new Matrix4();
  points.forEach((point, index) => {
    if (!shouldDisplayFn || shouldDisplayFn(point)) {
      getTranslationMatrix(point, tempMatrix);
      mesh.setMatrixAt(index, tempMatrix);
      if (getBlockColour) {
        mesh.setColorAt(index, getBlockColour(point));
      } else {
        mesh.setColorAt(index, point.colour);
      }
    } else {
      const hiddenPoint = clone(point);
      hiddenPoint.scale = 0;
      getTranslationMatrix(hiddenPoint, tempMatrix);
      mesh.setMatrixAt(index, tempMatrix);
    }
  });
  mesh.instanceMatrix.needsUpdate = true;
  // @ts-ignore
  mesh.instanceColor.needsUpdate = true;
};

const loadMeshObject = (
  points: ExplicitPoint[],
  params: PointCloudViewportParams,
  analysisType: AnalysisType3D,
  geometry: BufferGeometry
) => {
  const isModel = analysisType === AnalysisType3D.Model;
  const transparent = params.isTransparent || isModel;
  const overridePointColour = isModel && params.overridePointColour;
  const material = new MeshPhongMaterial({
    transparent: transparent,
    clippingPlanes: [params.clippingPlane],
    opacity: transparent ? params.modelOpacity : 1,
    color: overridePointColour ? new Color(params.overrideColour) : new Color(0xffffff),
    depthWrite: geometry instanceof BoxGeometry && params.modelOpacity < 1 && transparent ? false : true,
  });

  const mesh = new InstancedMesh(geometry, material, points.length);
  loadInstancedMeshMatrices(mesh, points, params.pointSize);

  // We need to render the model after the defects, so that the defects are
  // visible through the model when transparent.
  mesh.renderOrder = analysisType === AnalysisType3D.Model ? 2 : 1;

  return mesh;
};

export const loadPartModelAsMesh = (geometry: BufferGeometry, params: View3DViewportParams) => {
  const material = new MeshPhysicalMaterial({
    color: params.overridePointColour ? new Color(params.overrideColour) : new Color(0xffffff),
    clippingPlanes: [params.clippingPlane],
    metalness: 0.25,
    roughness: 1,
    opacity: params.modelOpacity,
    transparent: true,
    clearcoat: 1.0,
    clearcoatRoughness: 1,
  });
  material.side = DoubleSide;
  const mesh = new Mesh(geometry, material);

  mesh.renderOrder = 3;

  return mesh;
};

// Parse a downloaded point cloud protobuf and add it to the scene as a cloud of cubes.
export const loadPointCloudAsMesh = (
  pointCloud: PointCloud2 | PointCloud3,
  analysisType: AnalysisType3D,
  params: View3DViewportParams,
  geometry: BufferGeometry
): PointCloudLoadResult => {
  const points = loadPoints(pointCloud);

  const object = loadMeshObject(points, params, analysisType, geometry);
  object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
  object.userData.pointData = points;

  const bounds = loadPointCloudBounds(pointCloud);

  return {success: true, object, bounds};
};

export const loadSimilarityComparisonsAsMesh = (
  pointCloud: PointCloud2 | PointCloud3,
  params: View3DViewportParams,
  geometry: BufferGeometry
): ComparisonLoadResult => {
  const comparisonPoints = loadComparisons(pointCloud, params.comparisonScaling!);
  const result: {[key in AnalysisType3D]?: Object3D} = {};

  Object.entries(comparisonPoints).forEach(([type, points]) => {
    const analysisType = type as AnalysisType3D;
    const object = loadMeshObject(points!, params, analysisType, geometry);

    object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
    object.userData.pointData = points;
    result[analysisType] = object;
  });

  return {success: true, comparisonPoints: result};
};

export const convertPointsToMesh = (
  pointCloud: Points,
  analysisType: AnalysisType3D,
  params: PointCloudViewportParams,
  geometry: BufferGeometry
) => {
  const points = pointCloud.userData.pointData;
  const mesh = loadMeshObject(points, params, analysisType, geometry);
  mesh.visible = pointCloud.visible;
  mesh.name = pointCloud.name;
  mesh.userData = pointCloud.userData;
  // @ts-ignore
  mesh.instanceColor = pointCloud.geometry.attributes.color;
  return mesh;
};

export const downloadPointCloud = async (
  uuid: string,
  partUuid: string,
  analysisType: AnalysisType3D,
  onDownload: (pointCloud: PointCloud2 | PointCloud3, partUuid: string, analysisType: AnalysisType3D) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const url = await pointCloudDownloadUrlGET(uuid);

    if (!url.success) {
      fail();
      return;
    }

    const extension = url.data.url.split('?')[0].split('.').pop() as string;

    axios
      .get(url.data.url, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loadResult = parsePointCloud(result.data, extension);
          if (loadResult.success) {
            onDownload(loadResult.pointCloud, partUuid, analysisType);
            resolve();
            return;
          }
        }
        fail();
      });
  });
};

export const downloadPartModel = async (
  uuid: string,
  partUuid: string,

  onDownload: (partModel: BufferGeometry, uuid: string, partUuid: string) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const url = await partModelAttachmentDownloadUrlGET(uuid);

    if (!url.success) {
      fail();
      return;
    }

    axios
      .get(url.data, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loader = new STLLoader();
          onDownload(loader.parse(result.data), uuid, partUuid);
          resolve();
          return;
        }
        fail();
      });
  });
};

export const downloadSimilarityPointCloud = async (
  url: string,
  partUuid: string,
  analysisType: AnalysisType3D,
  onDownload: (apiRes: any, partUuid: string, analysisType: AnalysisType3D) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const extension = url.split('?')[0].split('.').pop() as string;

    axios
      .get(url, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loadResult = parsePointCloud(result.data, extension);
          if (loadResult.success) {
            onDownload(loadResult.pointCloud, partUuid, analysisType);
            resolve();
            return;
          }
        }
        fail();
      });
  });
};
