export enum Resource {
  AUTHORIZATION,

  BUILD,
  BUILD_NOTE,

  MACHINE,
  USER,
  RESOURCE_PERMISSION,
  DEVICE,
  CALIBRATION,
  BATCH,
  MATERIAL,
  LAYER_IMAGE,
  ALERT,
  PART,
  POINT_CLOUD,
  MACHINE_PARAMETER,
  MACHINE_LOG,
  RECIPIENT,
  CONTACT,

  BUILD_ATTACHMENT,
  PART_MODEL_ATTACHMENT,
  BATCH_ATTACHMENT,
  MATERIAL_ATTACHMENT,
  SLICE_ATTACHMENT,

  ORGANIZATION,
  NOTIFICATION,
  SIMILARITY_REPORT,
  SIMILARITY_COMPARISON,
  BUILD_INTENSITY_REPORT,
  BUILD_REPORT,
  LOCATION_BASED_DEFECTS_REPORT,
  SENSOR_PROFILE,

  DEFECT_STATISTIC,
  DEFECT_NOTIFICATION,
  DEFECT_SUMMARY,
}

export enum MessageType {
  UPDATE,
  INSERT,
  DELETE,
}

export interface InsertMessage<T> {
  type: MessageType.INSERT;
  payload: T;
}

export interface UpdateMessage<T> {
  type: MessageType.UPDATE;
  payload: T;
}

export interface DeleteMessage<T> {
  type: MessageType.DELETE;
  payload: Partial<T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResourceMessage<T = {}> = {
  // T is json-parsed object of db entity
  resource: Resource;
} & (InsertMessage<T> | DeleteMessage<T> | UpdateMessage<T>);
