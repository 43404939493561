import {combineReducers} from 'redux';
import * as authReducer from './auth';

import {IBuild} from '@common/api/models/builds/IBuild';
import {IAlert} from '@common/api/models/devices/IAlert';
import {IMachineParameter} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import {IMachine, IMachinesTableGETResponse} from '@common/api/models/devices/machines/IMachine';
import {IUser} from '@common/api/models/users/IUser';
import {IBatchAttachment, IMaterialAttachment} from '@common/api/models/attachments/IAttachmentBase';
import {ISliceAttachmentGETResponse} from '@common/api/models/attachments/ISliceAttachment';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {IPointCloud} from '@common/api/models/builds/data/IPointCloud';
import {IRecipient} from '@common/api/models/builds/IRecipient';
import {IOrganization} from '@common/api/models/organization/IOrganization';
import {ILayerImage} from '@common/api/models/builds/data/ILayerImage';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {IMachineLog} from '@common/api/models/devices/machines/machineLogs/IMachineLog';
import {INotification} from '@common/api/models/wip/INotification';
import {IDefectSummaryGETResponse} from '@common/api/models/builds/data/defects/IDefectSummary';
import {DefectStatisticGETResponse} from '@common/api/models/builds/data/defects/IDefectStatistic';
import {IDefectNotification} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {IBuildNote} from '@common/api/models/builds/IBuildNote';
import {ISimilarityReport, ISimilarityComparison} from '@common/api/models/builds/data/ISimilarity';
import {IResourcePermission} from '@common/api/models/users/IResourcePermission';
import {Resource} from '@common/api/websocket/resourceMessage';
import {IBuildIntensityReportGETResponse} from '@common/api/models/builds/data/IBuildIntensityReport';
import {IBuildReportGETResponse} from '@common/api/models/builds/data/IBuildReport';
import {ISensorProfileGETResponse} from '@common/api/models/devices/ISensorProfile';

import * as themeReducer from './theme';
import {AuthState} from '../model/auth';
import {ThemeState} from '../model/theme';
import {createInitialLiveStore, LiveStoreState} from '../model/liveUpdateStore';
import {DeviceLiveStoreReducer, DeviceStoreState} from './liveUpdateStores/deviceReducer';
import {BuildLiveStoreReducer, BuildStoreState} from './liveUpdateStores/buildReducer';
import {
  SimilarityReportLiveReducers,
  SimilarityReportState,
  similarityReportInitialState,
} from './liveUpdateStores/similarityReportReducer';
import {BuildNotesLiveStoreReducer} from './liveUpdateStores/buildNotesReducer';
import {SensorProfileLiveReducers} from './liveUpdateStores/sensorProfileReducer';
import {IDevice} from '@common/api/models/devices/IDevice';
import {LiveStoreReducers} from './liveUpdateStore';
import * as supportReducer from './support';
import * as draftBuildReducer from './draftBuild';
import * as buildDataArchiveReducer from './buildDataArchive';
import {SupportStoreState} from '../model/support';
import {DraftBuildStoreState} from '../model/draftBuild';
import {BuildDataArchiveStoreState} from '../model/buildDataArchive';
import {initialSensorProfileState, SensorProfileStoreState} from '../model/sensorProfile';
import {BuildIntensityReportStoreState, initialBuildIntensityReportState} from '../model/buildIntensityReport';
import {BuildIntensityReportLiveReducers} from './liveUpdateStores/buildIntensityReportReducer';
import {IBuildAttachmentsGETResponse} from '@common/api/models/attachments/IBuildAttachment';
import {ILocationBasedDefectsReportGETResponse} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {
  initialLocationBasedDefectsReportStore,
  LocationBasedDefectsReportStoreState,
} from '../model/locationBasedDefects';
import {LocationBasedDefectsReportLiveReducers} from './liveUpdateStores/locationBasedDefectsReportReducer';
import {IPartModelAttachmentGETResponse} from '@common/api/models/attachments/IPartModelAttachment';

export interface RootState {
  auth: AuthState;
  theme: ThemeState;
  buildStore: BuildStoreState;
  deviceStore: DeviceStoreState;
  similarityReportStore: SimilarityReportState;
  //
  support: SupportStoreState;
  draftBuild: DraftBuildStoreState;
  buildDataArchive: BuildDataArchiveStoreState;
  sensorProfileStore: SensorProfileStoreState;
  buildIntensityReportStore: BuildIntensityReportStoreState;
  locationBasedDefectsReportStore: LocationBasedDefectsReportStoreState;
  //
  authorizationStore: LiveStoreState<IResourcePermission>;
  //
  similarityComparisonStore: LiveStoreState<ISimilarityComparison>;
  userStore: LiveStoreState<IUser>;
  resourcePermissionStore: LiveStoreState<IResourcePermission>;
  machineStore: LiveStoreState<IMachinesTableGETResponse>;
  materialStore: LiveStoreState<IMaterial>;
  batchStore: LiveStoreState<IBatch>;
  calibrationStore: LiveStoreState<ICalibration>;
  machineParameterStore: LiveStoreState<IMachineParameter>;
  machineLog: LiveStoreState<IMachineLog>;
  alertStore: LiveStoreState<IAlert>;
  sliceAttachmentStore: LiveStoreState<ISliceAttachmentGETResponse>;
  buildAttachmentStore: LiveStoreState<IBuildAttachmentsGETResponse>;
  partModelAttachmentStore: LiveStoreState<IPartModelAttachmentGETResponse>;
  materialAttachmentStore: LiveStoreState<IMaterialAttachment>;
  batchAttachmentStore: LiveStoreState<IBatchAttachment>;
  layerImageStore: LiveStoreState<ILayerImage>;
  partStore: LiveStoreState<IPartGETResponse>;
  pointCloudStore: LiveStoreState<IPointCloud>;
  recipientStore: LiveStoreState<IRecipient>;
  organizationStore: LiveStoreState<IOrganization>;
  notificationStore: LiveStoreState<INotification>;
  defectStatisticStore: LiveStoreState<DefectStatisticGETResponse>;
  defectNotificationStore: LiveStoreState<IDefectNotification>;
  defectSummaryStore: LiveStoreState<IDefectSummaryGETResponse>;
  buildNoteStore: LiveStoreState<IBuildNote>;
  buildReportStore: LiveStoreState<IBuildReportGETResponse>;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>
  combineReducers({
    ...authReducer,
    ...themeReducer,
    //
    ...supportReducer,
    ...draftBuildReducer,
    ...buildDataArchiveReducer,
    //
    buildStore: BuildLiveStoreReducer.createReducer('uuid', Resource.BUILD, {
      ...createInitialLiveStore<IBuild>(),
      byMachineUuid: {},
    }),
    deviceStore: DeviceLiveStoreReducer.createReducer('serial', Resource.DEVICE, {
      ...createInitialLiveStore<IDevice>(),
      byMachineUuid: {},
    }),
    similarityReportStore: SimilarityReportLiveReducers.createReducer('uuid', Resource.SIMILARITY_REPORT, {
      ...createInitialLiveStore<ISimilarityReport>(),
      ...similarityReportInitialState,
    }),
    sensorProfileStore: SensorProfileLiveReducers.createReducer('uuid', Resource.SENSOR_PROFILE, {
      ...createInitialLiveStore<ISensorProfileGETResponse>(),
      ...initialSensorProfileState,
    }),
    buildNoteStore: BuildNotesLiveStoreReducer.createReducer(
      ['buildUuid', 'userUuid', 'createdAt'],
      Resource.BUILD_NOTE,
      createInitialLiveStore<IBuildNote>()
    ),
    //
    buildIntensityReportStore: BuildIntensityReportLiveReducers.createReducer('uuid', Resource.BUILD_INTENSITY_REPORT, {
      ...createInitialLiveStore<IBuildIntensityReportGETResponse>(),
      ...initialBuildIntensityReportState,
    }),
    buildReportStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.BUILD_REPORT,
      createInitialLiveStore<IBuildReportGETResponse>()
    ),
    locationBasedDefectsReportStore: LocationBasedDefectsReportLiveReducers.createReducer(
      'uuid',
      Resource.LOCATION_BASED_DEFECTS_REPORT,
      {
        ...createInitialLiveStore<ILocationBasedDefectsReportGETResponse>(),
        ...initialLocationBasedDefectsReportStore,
      }
    ),

    similarityComparisonStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.SIMILARITY_COMPARISON,
      createInitialLiveStore<ISimilarityComparison>()
    ),
    notificationStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.NOTIFICATION,
      createInitialLiveStore<INotification>()
    ),
    userStore: LiveStoreReducers.createReducer('uuid', Resource.USER, createInitialLiveStore<IUser>()),
    resourcePermissionStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.RESOURCE_PERMISSION,
      createInitialLiveStore<IResourcePermission>()
    ),
    authorizationStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.AUTHORIZATION,
      createInitialLiveStore<IResourcePermission>()
    ),
    machineStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.MACHINE,
      createInitialLiveStore<IMachinesTableGETResponse>()
    ),
    materialStore: LiveStoreReducers.createReducer('uuid', Resource.MATERIAL, createInitialLiveStore<IMaterial>()),
    batchStore: LiveStoreReducers.createReducer('uuid', Resource.BATCH, createInitialLiveStore<IBatch>()),
    calibrationStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.CALIBRATION,
      createInitialLiveStore<ICalibration>()
    ),
    machineParameterStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.MACHINE_PARAMETER,
      createInitialLiveStore<IMachine>()
    ),
    alertStore: LiveStoreReducers.createReducer('uuid', Resource.ALERT, createInitialLiveStore<IAlert>()),
    sliceAttachmentStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.SLICE_ATTACHMENT,
      createInitialLiveStore<ISliceAttachmentGETResponse>()
    ),
    batchAttachmentStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.BATCH_ATTACHMENT,
      createInitialLiveStore<IBatch>()
    ),
    buildAttachmentStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.BUILD_ATTACHMENT,
      createInitialLiveStore<IBuild>()
    ),
    partModelAttachmentStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.PART_MODEL_ATTACHMENT,
      createInitialLiveStore<IPartModelAttachmentGETResponse>()
    ),
    materialAttachmentStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.MATERIAL_ATTACHMENT,
      createInitialLiveStore<IMaterial>()
    ),
    machineLog: LiveStoreReducers.createReducer('uuid', Resource.MACHINE_LOG, createInitialLiveStore<IMachineLog>()),
    layerImageStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.LAYER_IMAGE,
      createInitialLiveStore<ILayerImage>()
    ),
    partStore: LiveStoreReducers.createReducer('uuid', Resource.PART, createInitialLiveStore<IPartGETResponse>()),
    pointCloudStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.POINT_CLOUD,
      createInitialLiveStore<IPointCloud>()
    ),
    recipientStore: LiveStoreReducers.createReducer('uuid', Resource.RECIPIENT, createInitialLiveStore<IRecipient>()),
    organizationStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.ORGANIZATION,
      createInitialLiveStore<IOrganization>()
    ),
    defectStatisticStore: LiveStoreReducers.createReducer(
      'uuid', // fixme: deletes won't work as uuid will not be in T
      Resource.DEFECT_STATISTIC,
      createInitialLiveStore<DefectStatisticGETResponse>()
    ),
    defectSummaryStore: LiveStoreReducers.createReducer(
      'name',
      Resource.DEFECT_SUMMARY,
      createInitialLiveStore<IDefectSummaryGETResponse>()
    ),
    defectNotificationStore: LiveStoreReducers.createReducer(
      'uuid',
      Resource.DEFECT_NOTIFICATION,
      createInitialLiveStore<IDefectNotification>()
    ),
  });
