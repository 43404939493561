import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {Axis} from '../Base3DViewport';
import {View3DViewportParams} from '../View3DViewport';
import ConditionalTooltip from '../../../../atoms/Texts/ConditionalTooltip';

// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
export const NoSpinnerInput = styled(Input)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  margin-bottom: -14px;
`;

export default function ClippingPlaneOptions(props: ViewportSidebarProps<View3DViewportParams>) {
  const clippingPlanePositionRef = useRef() as any;
  const layerImagePosition = useRef() as any;

  // Only valid if clipping plane direction is set to 'x' or 'z'.
  // Contains the width of the scene (mm) in the direction of the clipping plane.
  const horizontalPartWidth = props.sceneBounds
    ? props.params.clippingPlaneDirection === 'x'
      ? props.sceneBounds.dimensions.x
      : Math.abs(props.sceneBounds.dimensions.z)
    : null;

  const minLayerNum = Math.min(...props.params.selectedParts.map((part) => part.layerStart || 1));
  const maxLayerNum = Math.max(...props.params.selectedParts.map((part) => part.layerEnd || 0));
  const numLayers = maxLayerNum - minLayerNum + 1;

  const clippingPlanePositionToLayerNum = (clippingPlanePosition: number) =>
    Math.round(clippingPlanePosition * numLayers + minLayerNum);
  const layerNumToClippingPlanePosition = (layerNum: number) =>
    Math.min((Number(layerNum) - minLayerNum) / numLayers, 1);

  const [internalLayerNum, setInternalLayerNum] = useState<number | null>();
  const [internalOpacity, setInternalOpacity] = useState<number>(props.params.layerImageOpacity);

  useEffect(() => {
    if (!props.params.selectedParts.length) {
      setInternalLayerNum(null);
    } else if (!internalLayerNum) {
      setInternalLayerNum(clippingPlanePositionToLayerNum(props.params.clippingPlanePosition));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params.selectedParts]);

  const changeLayer = useCallback((layerNum: number, layerOnly: boolean = false) => {
    // @ts-ignore
    props.setParams((params) => ({
      ...params,
      layerImagePosition: layerNum,
      clippingPlanePosition: layerOnly
        ? params.clippingPlanePosition
        : Math.min((Number(layerNum) - minLayerNum) / numLayers, 1),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeLayer = useCallback(debounce(changeLayer, 250), [changeLayer]);
  const noPartsSelected = !props.params.selectedParts.length && !props.params.selectedPartModels?.length;
  const disableInputs = noPartsSelected || (props.params.clipLayerImagePlane && props.params.showLayerImage);

  return (
    <ConditionalTooltip
      tooltip="Clipping plane options disbaled when layer image is shown and clipping layer image is enabled"
      hideTooltip={!props.params.clipLayerImagePlane || !props.params.showLayerImage}
    >
      <>
        <Grid item>
          <Typography>Clipping plane / Layer Image</Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.params.clippingPlaneEnabled}
                onChange={(event) =>
                  props.setParams({
                    ...props.params,
                    clippingPlaneEnabled: event.target.checked,
                  })
                }
                disabled={disableInputs}
                name="ClippingPlaneEnabled"
              />
            }
            label="Enable clipping plane"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.params.showLayerImage}
                onChange={(event) =>
                  props.setParams({
                    ...props.params,
                    showLayerImage: event.target.checked,
                    layerImagePosition:
                      props.params.clippingPlaneDirection === 'y'
                        ? clippingPlanePositionToLayerNum(props.params.clippingPlanePosition)
                        : props.params.layerImagePosition,
                  })
                }
                disabled={noPartsSelected || numLayers <= 0}
                name="LayerImageEnabled"
              />
            }
            label="Show layer image"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.params.clippingPlaneReverse}
                onChange={(event) =>
                  props.setParams({
                    ...props.params,
                    clippingPlaneReverse: event.target.checked,
                  })
                }
                disabled={disableInputs}
                name="ClippingPlaneReverse"
              />
            }
            label="Reverse direction"
          />
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="planeNormal"
              name="planeNormal"
              value={props.params.clippingPlaneDirection}
              onChange={(event) => {
                props.setParams({
                  ...props.params,
                  clippingPlaneDirection: event.target.value as Axis,
                  clippingPlanePosition:
                    event.target.value === 'y' && props.params.showLayerImage
                      ? layerNumToClippingPlanePosition(Number(props.params.layerImagePosition))
                      : props.params.clippingPlanePosition,
                });
              }}
            >
              <FormControlLabel value="x" control={<Radio color="primary" />} label="X" disabled={disableInputs} />
              <FormControlLabel
                value="z" // Note that three.js uses different coordinates
                control={<Radio color="primary" />}
                label="Y"
                disabled={disableInputs}
              />
              <FormControlLabel value="y" control={<Radio color="primary" />} label="Z" disabled={disableInputs} />
            </RadioGroup>
          </FormControl>

          {props.params.selectedParts.length > 0 &&
            (props.params.clippingPlaneDirection === 'y' ? (
              <NoSpinnerInput
                inputRef={clippingPlanePositionRef}
                value={internalLayerNum}
                type="number"
                disableUnderline
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (Number(event.target.value) >= minLayerNum && Number(event.target.value) <= maxLayerNum) {
                    props.setParams({
                      ...props.params,
                      clippingPlanePosition: layerNumToClippingPlanePosition(Number(event.target.value)),
                    });
                    debouncedChangeLayer(Number(event.target.value));
                  }
                  setInternalLayerNum(Number(event.target.value));
                }}
                disabled={disableInputs}
                onClick={(e) => {
                  clippingPlanePositionRef.current.select();
                  e.stopPropagation();
                }}
                startAdornment={<InputAdornment position="start">Layer number:</InputAdornment>}
                inputProps={{
                  min: minLayerNum,
                  max: props.sceneBounds?.layerBounds?.max || minLayerNum + numLayers,
                  step: 1,
                }}
              />
            ) : (
              horizontalPartWidth && (
                // clipping plane direction is x or z i.e. horizontal
                <NoSpinnerInput
                  inputRef={clippingPlanePositionRef}
                  value={(props.params.clippingPlanePosition * horizontalPartWidth).toFixed(3)}
                  type="number"
                  disableUnderline
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.setParams({
                      ...props.params,
                      clippingPlanePosition: Number(event.target.value) / horizontalPartWidth,
                    });
                  }}
                  disabled={disableInputs}
                  onClick={(e) => {
                    clippingPlanePositionRef.current.select();
                    e.stopPropagation();
                  }}
                  startAdornment={<InputAdornment position="start">Plane position:</InputAdornment>}
                  endAdornment="mm"
                  inputProps={{
                    min: 0,
                    max: horizontalPartWidth,
                    step: 0.001,
                  }}
                />
              )
            ))}
        </Grid>
        {!!props.params.selectedParts.length && (
          <Grid item style={{marginTop: '6px'}}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>{props.params.clippingPlaneDirection === 'y' ? minLayerNum : 0}</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  track={props.params.clippingPlaneReverse ? 'inverted' : 'normal'}
                  value={props.params.clippingPlanePosition * 100}
                  onChange={(_, newValue) => {
                    props.setParams({
                      ...props.params,
                      clippingPlanePosition: (newValue as number) / 100,
                    });
                    if (props.params.clippingPlaneDirection === 'y') {
                      setInternalLayerNum(clippingPlanePositionToLayerNum(Number(newValue) / 100));
                    }
                  }}
                  onChangeCommitted={(_, newValue) => {
                    if (props.params.clippingPlaneDirection === 'y') {
                      const layerNum = clippingPlanePositionToLayerNum(Number(newValue) / 100);
                      props.setParams({
                        ...props.params,
                        layerImagePosition: layerNum,
                      });
                      setInternalLayerNum(layerNum);
                    }
                  }}
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item>
                <Typography>
                  {props.params.clippingPlaneDirection === 'y'
                    ? props.sceneBounds?.layerBounds?.max || minLayerNum + numLayers
                    : horizontalPartWidth?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {props.params.clippingPlaneDirection !== 'y' && (
          <>
            <NoSpinnerInput
              inputRef={layerImagePosition}
              value={internalLayerNum}
              type="number"
              disableUnderline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (Number(event.target.value) >= minLayerNum && Number(event.target.value) <= maxLayerNum) {
                  debouncedChangeLayer(Number.parseInt(event.target.value), true);
                }
                setInternalLayerNum(Number.parseInt(event.target.value));
              }}
              onClick={(e) => {
                layerImagePosition.current.select();
                e.stopPropagation();
              }}
              startAdornment={<InputAdornment position="start">Layer image number:</InputAdornment>}
              inputProps={{
                min: minLayerNum,
                max: props.sceneBounds?.layerBounds?.max || minLayerNum + numLayers,
                step: 1,
              }}
            />
            <Grid item style={{marginTop: '6px'}}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography>{minLayerNum}</Typography>
                </Grid>
                <Grid item xs>
                  <Slider
                    min={minLayerNum}
                    max={maxLayerNum}
                    value={internalLayerNum!}
                    onChange={(_, newValue) => setInternalLayerNum(newValue as number)}
                    onChangeCommitted={(_, newValue) => changeLayer(newValue as number, true)}
                  />
                </Grid>
                <Grid item>
                  <Typography>{props.sceneBounds?.layerBounds?.max || minLayerNum + numLayers}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {props.params.showLayerImage && (
          <Grid item style={{marginTop: '6px'}}>
            <Typography>
              <span style={{color: 'rgba(255, 255, 255, 0.7)'}}>Layer image opacity:</span> {internalOpacity}
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>0</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  min={0}
                  max={1}
                  value={internalOpacity}
                  onChange={(_, newValue) => setInternalOpacity(newValue as number)}
                  onChangeCommitted={(_, newValue) =>
                    props.setParams({
                      ...props.params,
                      layerImageOpacity: newValue as number,
                    })
                  }
                  step={0.05}
                />
              </Grid>
              <Grid item>
                <Typography>1</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </ConditionalTooltip>
  );
}
