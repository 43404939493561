import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {isEqual} from 'lodash';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grow,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {IBuild} from '@common/api/models/builds/IBuild';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {DeviceState, IDevice} from '@common/api/models/devices/IDevice';
import {RootState} from '../../../../../store/reducers/index';
import {GenericDialog} from '../../../../../components/molecules/DialogButton';
import {buildStopGET, monitorBuildGET} from '../../../../../api/ajax/builds';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../../components/atoms/Texts/ConditionalTooltip';
import {useMachineStatusText} from '../../../draftBuild/StepNavigationButtons';
import {useCameraState} from '../../shared/CameraState';

const OverviewActions = ({
  build,
  device,
  calibrationAvailable,
  isCurrentlyCalibrating,
  isCurrentlyFocusing,
  focusFailed,
  failedCalibration,
}: {
  build: IBuild;
  device: IDevice;
  calibrationAvailable: boolean;
  isCurrentlyCalibrating: boolean;
  isCurrentlyFocusing: boolean;
  focusFailed: boolean;
  failedCalibration: boolean;
}) => {
  const {allCamerasConnected} = useCameraState(device.serial);
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const [accepting, setAccepting] = useState(false);
  const [noSliceModalOpen, setNoSliceModalOpen] = useState(false);

  const [startAtLayerModalOpen, setStartAtLayerModalOpen] = useState(false);

  const [additionalOptionsOpen, setAdditionalOptionsOpen] = React.useState(false);
  const [startLayerNumber, setStartLayerNumber] = useState<string>('1');

  const startLayerNumberValid = React.useMemo(() => {
    return !isNaN(parseInt(startLayerNumber)) && parseInt(startLayerNumber) > 0;
  }, [startLayerNumber]);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleAdditionalOptions = () => {
    setAdditionalOptionsOpen((prevOpen) => !prevOpen);
  };

  const closeAdditionalOptions = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAdditionalOptionsOpen(false);
  };

  const machineStatusText = useMachineStatusText(build);

  const buttonStyle = isSmallScreen ? {marginTop: '12px'} : {marginLeft: '12px'};

  const sliceFiles = useSelector(
    (state: RootState) =>
      Object.values(state.sliceAttachmentStore.byId).filter((slice) => slice.buildUuid === build.uuid),
    isEqual
  );

  const validSlices = sliceFiles
    .filter((slice) => !slice.deletedAt)
    .filter((slice) => slice.state !== SliceState.INVALID);

  const acceptAndMonitor = async (force: boolean) => {
    setAdditionalOptionsOpen(false);
    setStartAtLayerModalOpen(false);

    if (!startLayerNumberValid) return;

    if (!force && validSlices.length === 0) {
      setNoSliceModalOpen(true);
      return;
    }

    setNoSliceModalOpen(false);
    setAccepting(true);

    if (device && (device.state === DeviceState.CALIBRATING || device.state === DeviceState.PREVIEWING)) {
      await buildStopGET(build.uuid);
    }

    const res = await monitorBuildGET(build.uuid, false, parseInt(startLayerNumber));

    setAccepting(false);
    if (res.success) {
      localStorage.setItem(`${build.uuid}-staging-complete`, String(Date.now()));
      toast('Monitor request sent. Waiting for device...', {type: 'success'});
      history.push(`/builds/uuid/${build.uuid}`);
    }
  };

  // Disabled if one of the below is true:
  // - Device is offline
  // - User doesn't have machine permissions for device
  // - No calibration available
  // - Calibration failed (user can select to use most recent calibration)
  // - Calibration in progress
  // - Focus in progress
  // - Focus failed
  const disabledButtonTooltip = machineStatusText
    ? machineStatusText
    : !calibrationAvailable
    ? 'No calibration result available. Please calibrate the build before proceeding.'
    : isCurrentlyCalibrating
    ? 'Calibration in progress, please wait for calibration to finish or stop the calibration operation.'
    : failedCalibration
    ? 'The most recent calibration failed. Please re-run calibration or use the last successful calibration.'
    : isCurrentlyFocusing
    ? 'Focusing in progress, please wait for focus to finish or stop the focus operation.'
    : !allCamerasConnected
    ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before starting to monitor.'
    : '';

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" mt={isSmallScreen ? 1 : 4}>
      {focusFailed ? (
        <Alert severity="warning" style={{width: '100%', marginBottom: '12px'}}>
          Some cameras failed to focus. You can still proceed to monitoring, though it is recommended to retry to
          auto-focus operation or manually focus the cameras.
        </Alert>
      ) : null}
      <ConditionalTooltip tooltip={disabledButtonTooltip} hideTooltip={!disabledButtonTooltip}>
        <>
          <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => acceptAndMonitor(false)}
              disabled={!!disabledButtonTooltip || accepting || !allCamerasConnected}
              style={buttonStyle}
              fullWidth={isSmallScreen}
              size={isSmallScreen ? 'small' : 'medium'}
            >
              Accept and Start Monitoring
              {accepting && <CircularProgress style={{marginLeft: '12px'}} size={20} />}
            </Button>
            <Button
              color="primary"
              size="small"
              disabled={!!disabledButtonTooltip || accepting || !allCamerasConnected}
              onClick={handleAdditionalOptions}
            >
              {additionalOptionsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </ButtonGroup>
          <Popper
            open={additionalOptionsOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-end' ? 'top' : 'bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={closeAdditionalOptions}>
                    <List dense>
                      <ListItem button onClick={() => acceptAndMonitor(false)}>
                        <ListItemText
                          primary="Accept and start monitoring"
                          secondary="The first image will be output as layer 1"
                        />
                      </ListItem>
                      <Divider />
                      <ListItem button onClick={() => setStartAtLayerModalOpen(true)}>
                        <ListItemText
                          primary="Accept and start monitoring From layer"
                          secondary="The first image will be output as the layer number you provide"
                        />
                      </ListItem>
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      </ConditionalTooltip>
      <GenericDialog
        title="No Slice Files Uploaded"
        isOpen={noSliceModalOpen}
        closeDialog={() => {
          setNoSliceModalOpen(false);
        }}
        content="This build currently has no slice files attached to it. We recommend that you upload these before proceeding to the next stage."
        closeText="Go Back & Upload"
        confirmText="Skip Upload & Continue"
        onSuccess={() => acceptAndMonitor(true)}
        maxWidth="xl"
      />
      <GenericDialog
        title="Start at Layer"
        isOpen={startAtLayerModalOpen}
        closeDialog={() => {
          setStartAtLayerModalOpen(false);
        }}
        content={
          <>
            <TextField
              fullWidth
              required
              variant="outlined"
              helperText={`The first image will be output as the layer number you provide`}
              size="small"
              type="number"
              label="Start at layer"
              value={startLayerNumber}
              onChange={(event) => {
                setStartLayerNumber(event.target.value);
              }}
            />
          </>
        }
        closeText="Cancel"
        confirmText="Confirm"
        onSuccess={() => acceptAndMonitor(false)}
        confirmButtonProps={{disabled: !startLayerNumberValid}}
        maxWidth="sm"
        fullWidth
      />
    </Box>
  );
};

export default OverviewActions;
