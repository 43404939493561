import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
  Grid,
  ButtonProps,
  DialogProps,
  Box,
  makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {dangerColor} from '../../assets/jss/material-dashboard-react';
import CustomButton, {CustomButtonProps} from '../atoms/CustomButton';
import {CircularProgress} from '@material-ui/core';

interface ConfirmDialogButtonProps {
  title: string;
  content: string | JSX.Element;
  confirmText?: string;
  icon?: JSX.Element;
  requestInProgress?: boolean;
  confirmButtonProps?: Partial<ButtonProps>;
}

interface ConfirmDialogProps extends ConfirmDialogButtonProps {
  isOpen: boolean;
  closeDialog: () => void;
  onSuccess?: () => void;
  danger?: boolean;
  closeText?: string;
  confirmDisabled?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
  additionalConfirms?: Array<{
    onSuccess: () => void;
    confirmText: string;
    confirmButtonProps?: Partial<ButtonProps>;
    confirmDisabled?: boolean;
    requestInProgress?: boolean;
  }>;
  hideCloseTextButton?: boolean;
  topAlign?: boolean;
}

const useTopAlignStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export function GenericDialog(props: ConfirmDialogProps) {
  const {
    title,
    content,
    confirmText,
    isOpen,
    closeDialog,
    onSuccess,
    danger,
    icon,
    requestInProgress,
    confirmButtonProps,
    closeText,
    confirmDisabled,
    maxWidth = 'xs',
    fullWidth,
    additionalConfirms,
    hideCloseTextButton = false,
    topAlign,
  } = props;
  const topAlignClasses = useTopAlignStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      open={isOpen}
      onClose={closeDialog}
      fullWidth={fullWidth}
      classes={
        topAlign
          ? {
              scrollPaper: topAlignClasses.topScrollPaper,
              paperScrollBody: topAlignClasses.topPaperScrollBody,
            }
          : undefined
      }
    >
      <DialogTitle id="confirmation-dialog-title" disableTypography style={{padding: '24px'}}>
        <Grid container justifyContent="space-between" alignItems="center" style={{flexWrap: 'nowrap'}}>
          <Box display="flex" alignItems="center">
            {icon && icon}
            <Typography
              variant="h4"
              style={{
                marginLeft: icon ? '12px' : undefined,
                marginTop: icon ? '2px' : undefined,
              }}
            >
              {title}
            </Typography>
          </Box>
          <div>
            <IconButton aria-label="close" onClick={closeDialog} style={{padding: '0px', marginLeft: '24px'}}>
              <Close />
            </IconButton>
          </div>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{margin: '0px'}}>
        {React.isValidElement(content) ? content : <Typography>{content}</Typography>}
      </DialogContent>
      <DialogActions style={{padding: '16px 24px'}}>
        {!hideCloseTextButton && (
          <Button onClick={closeDialog} variant="outlined" disabled={requestInProgress} color="primary">
            {closeText ? closeText : 'Cancel'}
          </Button>
        )}
        {additionalConfirms &&
          additionalConfirms.map((confirmButton) => (
            <Button
              onClick={confirmButton.onSuccess}
              color="primary"
              variant={'contained'}
              disabled={confirmButton.confirmDisabled || confirmButton.requestInProgress}
              {...confirmButton.confirmButtonProps}
              endIcon={
                confirmButton.requestInProgress ? (
                  <CircularProgress size={20} />
                ) : (
                  confirmButton.confirmButtonProps?.endIcon
                )
              }
            >
              {confirmButton.confirmText}
            </Button>
          ))}
        {onSuccess && (
          <Button
            onClick={onSuccess}
            color="primary"
            variant={'contained'}
            style={danger && !requestInProgress ? {backgroundColor: dangerColor[0]} : {}}
            disabled={confirmDisabled || requestInProgress}
            {...confirmButtonProps}
            endIcon={requestInProgress ? <CircularProgress size={20} /> : confirmButtonProps?.endIcon}
          >
            {confirmText ?? 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface DialogButtonProps extends CustomButtonProps {
  dialog: ConfirmDialogButtonProps;
  handleConfirm: (() => Promise<void>) | (() => void);
  danger?: boolean;
}

export function DialogButton({dialog, handleConfirm, danger, ...props}: DialogButtonProps) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [performingAction, setPerformingAction] = useState(false);

  const onButtonClick = () => {
    setShowConfirmDialog(true);
  };
  const handleCancel = () => {
    setShowConfirmDialog(false);
  };
  const handleConfirmation = async () => {
    setShowConfirmDialog(false);
    setPerformingAction(true);
    await handleConfirm();
    setPerformingAction(false);
  };

  return (
    <React.Fragment>
      <CustomButton
        variant={danger ? 'contained' : 'outlined'}
        color={'primary'}
        onClick={onButtonClick}
        loading={performingAction || props.loading}
        style={
          danger && !(performingAction || props.loading) && !props.disabled
            ? {backgroundColor: dangerColor[0], color: 'white'}
            : {}
        }
        disabled={props.disabled || performingAction}
        {...props}
      />

      <GenericDialog
        isOpen={showConfirmDialog}
        closeDialog={handleCancel}
        onSuccess={handleConfirmation}
        danger={danger}
        {...dialog}
      />
    </React.Fragment>
  );
}
